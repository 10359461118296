import React from 'react'
import SEO from '../components/seo'
import useTranslations from '../components/useTranslations'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import useHomeContent from '../components/useHomeContent'
import { Link } from 'gatsby'
import { useLocale } from '../hooks/locale'

const Login = () => {
    const homeContent = useHomeContent()
    const { locale } = useLocale()

    return (
        <>
            <SEO title='Teo Rojas' />
            <div className='bg-dark min-h-auto w-full'>
                <div className='container h-full mx-auto max-w-4xl sm:flex text-justify p-8'>
                    <div className='sm:w-1/3 sm:pr-4 text-center pb-6 sm:pb-0'>
                        <StaticImage src={`../../static/assets/img/teo-rojas.jpg`} alt='Teo Rojas' />
                    </div>
                    {locale === 'zh' && (<div className='sm:w-2/3 sm:pl-4 text-white'>
                        <p className='mb-4'>Teo
                            Rojas是上海牌顺品牌管理有限公司创始人，出生于秘鲁，在拉斯维加斯（美国）接受培训，拥有16年的工作经验，过去10年专注于奢侈品牌和房地产开发，在美洲、中东和亚洲都曾开展过项目。</p>
                        <p className='mb-4'>他的专业知识包括品牌创建和用户体验、设计、施工和项目管理。他拥有多个知名项目的方向和战略的管理经验：
                            萨尔瓦多·菲拉格慕、汤米·希尔菲格、美洲的波罗·拉夫·劳伦和亚太地区的飒拉（Inditex集团）、范思哲和芬迪以及购物中心项目。</p>
                        <p className='mb-4'>Teo游历广泛，对客户体验、设计方法和施工技术有着深刻的了解，他热衷于通过对空间和组件的设计带给客户深刻的体验，最近他已经收到英国皇家空军Marangoni时装学院、上海中国国际家具博览会、零售成就论坛、中国协同发展论坛的演讲邀请。目前，他正在WeWork
                            labs平台下管理多个初创企业。</p>
                    </div>)}
                    {locale !== 'zh' && (<div className='sm:w-2/3 sm:pl-4 text-white'>
                        <p className='mb-4'>Teo
                            Teo Rojas is BRANDON founder, an architect born in Perú, trained in Las Vegas (USA) with 16
                            years working experience, focusing the last 10 years in luxury brands and real estate
                            developments, with projects in the Americas, Middle East and Asia.</p>
                        <p className='mb-4'>His expertise includes Branding & User Experience , Design, Construction and
                            Project Management. He has been managing the infraestructure development for different well
                            know projects : Salvatore Ferragamo, Tommy Hilfiger, Polo Ralph Lauren in the Americas and
                            Zara ( Inditex Group ), Versace & Fendi in Asia Pacific and Shopping Center Projects as
                            well.</p>
                        <p className='mb-4'>Teo has traveled widely, which allows him to know deeply about customer
                            experience, design methods and construction techniques, he has passion for creating customer
                            feelings reflected in spaces and components, currently he receives invitations for giving
                            lectures in Marangoni Fashion Institute, Raffles Design Institute, China International
                            Furniture Fair in Shanghai, Retail Fulfilment Forum & China Coworking Development Forum.
                            Currently he is mentoring different startups under WeWork Labs Platform.</p>
                    </div>)}
                </div>
            </div>
        </>
    )
}
export default Login